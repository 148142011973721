<template>
  <div class="billingUpload">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">Upload Sorted Billing Information</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading" class="mb-4">
        <v-container v-if="upload">
          <v-form v-model="valid" class="ma-2" @submit.prevent>
            <v-row>
              <v-col md="4">
                <v-file-input
                  show-size
                  counter
                  multiple
                  dense
                  outlined
                  prepend-icon=""
                  prepend-inner-icon="attach_file"
                  label="File input"
                  @change="handleFileUpload"
                ></v-file-input>
              </v-col>
              <v-col md="4">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="weekCommencing"
                      label="Week Commencing."
                      prepend-inner-icon="event"
                      readonly
                      dense
                      outlined
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="weekCommencing" @input="menu = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col md="4">
                <v-btn
                  class="ml-1 mr-1"
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  @click="uploadBillingFile()"
                >Upload File</v-btn>
                <v-btn
                  class="ml-1 mr-1"
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  @click="upload = false"
                >Query Form</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container v-if="!upload">
          <v-form v-model="valid" class="ma-2" @submit.prevent>
            <v-row>
              <company-select @chosen="setCompany" v-model="companySelected"></company-select>
              <date-picker @clicked="setDateRange" v-model="datesPicked"></date-picker>
              <v-col cols="6">
                <v-select
                  v-model="query.week_commencing"
                  :items="weeksCommencing"
                  item-text="week_commencing"
                  label="Week Commencing"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-btn
                  class="ml-1 mr-1"
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  @click="querySortedUploadData()"
                >Run Query</v-btn>
                <v-btn
                  class="ml-1 mr-1"
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  @click="upload = true"
                >Upload File</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card>
      <div v-if="data.length > 0">
        <v-btn
          :loading="loading"
          :disabled="loading"
          small
          color="primary"
          class="mb-2 mt-2"
          @click="exportToExcel('xlsx')"
          type="button"
        >Excel</v-btn>
        <v-btn
          :loading="loading"
          :disabled="loading"
          small
          color="primary"
          class="ml-2 mb-2 mt-2"
          @click="exportToExcel('csv')"
          type="button"
        >CSV</v-btn>
        <div class="billingTable">
          <v-card>
            <v-card-title>
              Billing Data
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="data"
              :items-per-page="5"
              :search="search"
              id="data-table"
            ></v-data-table>
          </v-card>
        </div>
      </div>
    </div>
    <v-snackbar v-model="snackbar" :timeout="5000">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false"></v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import SortedUpload from "@/services/SortedUpload.js";
import * as XLSX from 'xlsx'
import CompanySelect from "../components/forms/CompanySelect";
import DatePicker from "../components/forms/DatePicker";

export default {
  components: {
    CompanySelect,
    DatePicker
  },
  data() {
    return {
      upload: false,
      menu: false,
      // Snackbar
      snackbar: false,
      text: "",
      response: null,
      // Upload
      fileString: "",
      file: "",
      search: "",
      loading: false,
      companySelected: {},
      datesPicked: [],
      data: [],
      valid: false,
      weekCommencing: null,
      weeksCommencing: [],
      query: {
        companyCode: null,
        dateShippedFrom: null,
        dateShippedTo: null,
        week_commencing: null
      },
      headers: [
        {
          text: "Company Code",
          align: "left",
          value: "company_code"
        },
        { text: "Electio Reference", value: "electioReference" },
        { text: "Order Reference", value: "orderReference" },
        { text: "State", value: "state" },
        { text: "Carrier", value: "carrier_name" },
        { text: "Service", value: "service" },
        { text: "Shipped", value: "dateShipped" },
        { text: "Total", value: "totalCost" },
        { text: "No. of Packages", value: "numberOfPackages" },
/* REMOVED from 22/06/2020 - Change of Sorted Data
       { text: "Customer Name", value: "customerName" },
        { text: "Address", value: "addressLine1" },
        { text: "Postcode", value: "postcode" },
        { text: "Country", value: "country" },
        { text: "Tracking", value: "tracking" } */
      ]
    };
  },
  methods: {
    setCompany(company) {
      this.companySelected = company;
      this.query.companyCode = this.companySelected.code;
    },
    setDateRange(dates) {
      this.datesPicked = dates;
      this.query.dateShippedFrom = this.datesPicked[0];
      this.query.dateShippedTo = this.datesPicked[1];
      this.query.week_commencing = null;
    },
    exportToExcel(type, fn, dl) {
      var elt = document.getElementById("data-table");
      var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
      return dl
        ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
        : XLSX.writeFile(
            wb,
            fn ||
              "sortedBillingData_" + this.fileString + "." + (type || "xlsx")
          );
    },
    async handleFileUpload(ev) {
      this.file = ev[0];
    },
    async uploadBillingFile() {
      if (!this.file) {
        this.snackbar = true;
        this.text = `Please select a file which will be uploaded.`;
        this.loading = false;
        return false;
      }
      if (!this.weekCommencing) {
        this.snackbar = true;
        this.text = `Please select a week commencing.`;
        this.loading = false;
        return false;
      }
      this.loading = true;
      var formData = new FormData();
      formData.append("file", this.file);
      formData.append("API-KEY", process.env.VUE_APP_FUSION_API_KEY);
      formData.append("weekCommencing", this.weekCommencing);      
      SortedUpload.sortedBillingUpload(formData)
        .then(
          (response => {
            this.$set(this, "response", response);
            this.loading = false;
            this.text = `${this.response.successMessage[0]} for week commencing ${this.weekCommencing}.`;
            this.snackbar = true;
            SortedUpload.getSortedTotalCost().then(response => {
              if (response.success == 200) {
                this.query.week_commencing = this.weekCommencing;
                this.text = `The system will now check for any items without Total Cost, this could take a few minutes to complete.`;
                this.getSortedUploadData();
              }
            });
          }).bind(this)
        )
        .catch(error => {
          this.snackbar = true;
          this.text = `${error.response.data.errorMessage[0]}`;
          this.loading = false;
          return false;
        });
    },
    async getSortedUploadData() {
      this.fileString = `AllCompanies_${this.query.week_commencing}`;
      this.loading = true;
      // Use the BillingSnap to call the getBillingData() method
      SortedUpload.getSortedBillingData(this.query.week_commencing)
        .then(
          (data => {
            this.$set(this, "data", data);
            this.loading = false;
            this.snackbar = true;
            this.text = `Successfully returned Billing Data`;
          }).bind(this)
        )
        .catch(error => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return;
        });
    },
    async querySortedUploadData() {
      this.fileString = "";
      this.loading = true;
      if (
        !this.query.companyCode &&
        !this.query.dateShippedFrom &&
        !this.query.dateShippedTo
      ) {
        return this.getSortedUploadData();
      }
      // Use the BillingSnap to call the getBillingData() method
      SortedUpload.querySortedBillingData(this.query)
        .then(
          (data => {
            if (data.length > 0) {
              this.$set(this, "data", data);
              this.loading = false;
              this.snackbar = true;
              this.text = `Successfully returned Billing Data for`;
              for (const key in this.query) {
                if (this.query.hasOwnProperty(key)) {
                  const element = this.query[key];
                  if (element) {
                    this.fileString += `${element}_`;
                    this.text += ` ${key} - ${element} `;
                  }
                }
              }
            }
          }).bind(this)
        )
        .catch(error => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return;
        });
    },
    async getWeeksCommencing() {
      // Use the BillingSnap to call the getBillingData() method
      return SortedUpload.getSortedWeeksCommencing()
        .then(
          (weeksCommencing => {
            this.$set(this, "weeksCommencing", weeksCommencing);
            return true;
          }).bind(this)
        )
        .catch(error => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return false;
        });
    },
    async loadInitialData() {
      this.loading = true;
      let weeksCommencing = await this.getWeeksCommencing();
      if (weeksCommencing) {
        this.loading = false;
        this.text = `Upload Sorted CSV Files or Query the Sorted Data.`;
        this.snackbar = true;
      }
    }
  },
  created() {
    this.loadInitialData();
  },
  watch: {
    query: {
      handler(newVal) {
        if (newVal.week_commencing) {
          this.query.dateShippedFrom = null;
          this.query.dateShippedTo = null;
          this.datesPicked = [];
        }
      },
      deep: true
    }
  }
};
</script>
<style lang="scss" scoped>
.description {
  margin-bottom: 30px;
}
.error {
  background-color: red;
}
</style>